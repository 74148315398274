import React from 'react';
import { graphql } from 'gatsby';

// Context
import { SwiperStateProvider } from '../context/SwiperState';

// Import Views
import { SlideView } from '../components/SlideView';

import { Head } from '../components/Head';
import { Layout } from '../components/Layout';

import { FALLBACK_OG_IMAGES } from '../helpers/openGraph';

const IndexPage = ({ data: { contentfulHome: homeData } }) => {
  // console.log(homeData);

  const {
    title,
    description,
    content,
    keywords,
    node_locale: locale,
    backgroundColor,
    // sharingImage,
  } = homeData;

  return (
    <>
      <Head
        locale={locale}
        title={title}
        description={(description || {}).description}
        keywords={keywords}
        openGraph={{
          type: 'website',
          title,
          description: (description || {}).description,
          images: FALLBACK_OG_IMAGES,
        }}
      />
      <SwiperStateProvider>
        <Layout className="bg-fade">
          <SlideView data={content} isHome />
        </Layout>
      </SwiperStateProvider>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  {
    contentfulHome(slug: { eq: "home" }) {
      title
      description {
        description
      }
      id
      node_locale
      keywords
      backgroundColor
      content {
        ... on ContentfulSlideImage {
          __typename
          id
          title
          subtitle {
            subtitle
          }
          backgroundColor
          imageFit
          image {
            __typename
            id
            description
            file {
              contentType
              fileName
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
          category {
            __typename
            title
            page {
              __typename
              slug
              id
            }
            id
          }
        }
        ... on ContentfulSlideVideo {
          __typename
          id
          title
          subtitle {
            subtitle
          }
          backgroundColor
          vimeoId
          videoFit
          videoControls
          videoPoster {
                fixed(width: 1920, toFormat: JPG, quality: 60) {
                  src
                }
              }
          category {
            __typename
            title
            page {
              __typename
              slug
              id
            }
            id
          }
        }
      }
    }
  }
`;
